import type { StateSearchDateFilters } from '~/types/search/types'

export const useSearchDateFilters = () => {
  const stateSearchDateFilters = useState<StateSearchDateFilters>(
    'state-search-date-filter',
  )

  const { $httpCookies } = useNuxtApp()

  const startDate = computed(() => stateSearchDateFilters.value.startDate)
  const endDate = computed(() => stateSearchDateFilters.value.endDate)
  const hasDates = computed(() => Boolean(startDate.value && endDate.value))

  const clearDateFilters = () => {
    $httpCookies?.set('search_params', {})

    stateSearchDateFilters.value.endDate = null
    stateSearchDateFilters.value.startDate = null
  }

  const setDateStart = (date: Date) => {
    const parsed = date ? $dayjs(date) : null
    stateSearchDateFilters.value.startDate = parsed?.isValid()
      ? parsed.format('YYYY-MM-DD')
      : null
  }

  const setDateEnd = (date: Date) => {
    const parsed = date ? $dayjs(date) : null
    stateSearchDateFilters.value.endDate = parsed?.isValid()
      ? parsed.format('YYYY-MM-DD')
      : null

    setCookieDates()
  }

  const setCookieDates = () => {
    if (startDate.value && endDate.value) {
      const start = $dayjs(startDate.value)
      const end = $dayjs(endDate.value)

      $httpCookies?.set('search_params', {
        from_date: start.format('YYYY-MM-DD'),
        to_date: end.format('YYYY-MM-DD'),
      })
    } else if (startDate.value && !endDate.value) {
      $httpCookies?.set('search_params', {})
    }
  }

  return {
    clearDateFilters,
    setDateStart,
    setDateEnd,
    startDate,
    endDate,
    hasDates,
  }
}
